<template>
  <div v-if="shouldShowProgress" >
    <div class="flex justify-end">
      <button class="focus:outline-none" @click="displayTransportation">
        <span class="opacity-60">配送進度</span>
        <img class="btn-ico-triangle transform opacity-100" :class="{ 'rotate-180': displaying }" src="@/assets/img/ui/ui_triangle_down.svg" />
      </button>
    </div>
    <transition name="slide">
      <template v-if="displaying">
        <transport-progress v-if="shouldShowTransport" :delivery="delivery" />
        <return-progress v-else :step="delivery.step" />
      </template>
    </transition>
  </div>
</template>

<script>
import TransportProgress from './TransportProgress.vue'
import ReturnProgress from './ReturnProgress.vue'
export default {
  components: {
    TransportProgress,
    ReturnProgress
  },
  props: {
    status: {
      type: Number,
      default: 0
    },
    delivery: {
      type: Object,
      default: () => ({
        step: 0,
        provider: '',
        tracking: '',
        url: '',
        type: '',
        steps: {
          started_at: null,
          stocked_at: null,
          shipped_at: null,
          stopped_at: null
        }
      })
    }
  },
  data() {
    return {
      displaying: false, 
      packStatusList:  {
        '0':  '未付款',
        '1':  '已付款',
        '2':  '交易完成',
        '3':  '部分出貨',
        '10':  '用戶請求取消',
        '11':  '交易關閉',
        '12':  '交易關閉',
        '13':  '部分退貨',
        '14':  '退貨中',
      }
    }
  },
  computed: {
    currentStatusText({ status } ) {
      return this.packStatusList[status]
    },
    shouldShowTransport() {
      return this.delivery.step < 10
    },
    shouldShowProgress({ status }) {
      return status !== 11
    }
  },
  methods: {
  
    displayTransportation() {
      this.displaying = !this.displaying
    },
   
  }
}
</script>

<style lang="scss" scoped>

/*宅配動畫*/
.btn-ico-triangle {
  width: auto;
  height: 0.4rem;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
