<template>
  <div>
    <section class="bg-white rounded-xl p-4 mb-4 shadow-md md:bg-gray-10 md:shadow-none flex flex-col md:flex-row">
      <picture>
        <!-- <img class=" mr-8 rounded-lg hidden md:block w-72 h-72 object-fill" :src="orderImage" @click="handleClickImage" @error="imgNotFound" /> -->
      </picture>
      <div class="flex-1 flex flex-col">
        <!--v-if退貨-->
        <!--TODO 退貨號碼要填上-->

        <!-- 主圖、產品名稱、加入時間 -->
        <article class="flex mb-2 items-center order-1 justify-between">
          <!-- <img class="cart-item-img mr-2 block md:hidden" :src="orderImage" @click="handleClickImage" @error="imgNotFound" /> -->
          <div class="w-full">
            <div class="flex justify-start text-sm opacity-60 mb-1">
              <span>● {{ item.created_at }}</span>
              <div class="mx-3">
                <span>{{ item.paymentTypeCode == 'ECPay.WebATM' ? 'ATM付款' : item.paymentTypeCode == '' ? 'COCO幣全額折抵' : '信用卡付款' }}．</span>
                <span>{{ currentStatus }}</span>
              </div>
            </div>
            <!-- 訂單編號 -->
            <div class="flex items-center order-2 justify-start">
              <div class="mr-2">訂單編號：{{ item.no }}</div>
              <button v-clipboard:copy="item.no" v-clipboard:success="handleCopied">
                <font-awesome-icon icon="clone" />
              </button>
            </div>
            <p class="text-left text-brown font-medium md:text-left md:text-2xl md:mb-0">{{ item.product.name }}</p>
            <div class="flex justify-start items-start md:items-end flex-col md:flex-row">
                <span class="text-brown font-bold align-bottom">訂單金額：${{ item.origin_amount | currency }} - COCO幣：${{ cocoUsed | currency }}</span>
                <span class="text-brown font-bold mx-0 md:mx-5 align-bottom">結帳總額：<span class="text-pink-salmon">$</span><span class="text-pink-salmon text-4xl">{{ item.amount | currency }}</span></span>
            </div>
          </div>
        </article>
        
        <!-- 付款資訊 -->
        <div class="flex mb-2 items-center order-3 justify-end">
          <payment-info :payment="item.payment" :showPaymentInfo="item.status === 0 && item.payment.account !== ''" />
        </div>

        <!--金額-->
        <div class="md:flex md:justify-between order-4">
          <!--總計-->
          <article class="p-3 border-solid border-gray-200 md:flex-1 md:border-none">
            <div class="flex justify-between items-center">
              <div class="flex justify-between w-1/2 items-center">
                <span class="text-brown font-thin">均價</span>
                <div><span class="font-thin md:font-bold text-pink-salmon mr-1">{{ item.average }}</span><span class="text-gray-400"><small> /入</small></span></div>
              </div>
              <!-- <div class="w-5 text-center text-gray-200">|</div> -->
              
            </div>
          </article>

          <!--mobile TODO 待退款 / 已退款-->
          <!-- <article class="md:hidden p-2 border-solid border-b border-gray-200 md:flex-1 md:border-none">
            <div class="flex justify-between items-center">
              <div class="flex justify-between w-1/2 items-center"><span class="text-brown text-lg">待退款</span><span class="text-lg text-pink-salmon">$??</span></div>
            </div>
            <div class="flex justify-between items-center">
              <span class="mt-2 md:mt-0 opacity-50">已退款 ??${{ item.mockRefoundFinished }} 於 ??2021.04.06</span>
            </div>
          </article> -->

          <!--送貨狀態 -->
          <!-- <article class="mb-2 md:flex-1">
            <div class=" px-2 flex justify-between">
              <header class="flex items-center">
                <img src="@/assets/img/logo/icon_house.svg" class="w-5 h-5 mr-2" />
                <h1 class="text-brown my-2">送貨狀態</h1>
              </header>

              <button class="focus:outline-none" @click="show.shippingStatus = !show.shippingStatus">
                <span class="opacity-60">配送進度</span
                ><img class="btn-ico-triangle transform opacity-100" :class="{ 'rotate-180': show.shippingStatus }" src="../../assets/img/ui/ui_triangle_down.svg" />
              </button>
            </div>
            <transition name="slide">
              <div v-if="show.shippingStatus" class="p-2 flex justify-between">
                <ul class="p-4 mb-2 bg-gray-50 w-full">
                  <li class="mb-2"><span class="mr-2">貨件一??</span><span class="opacity-50 w-2/3">已派送至台北物流??</span></li>
                  <li class="mb-2"><span class="mr-2">貨件二??</span><span class="opacity-50 w-2/3">已準備發貨??</span></li>
                </ul>
              </div>
            </transition>
          </article> -->
        </div>

        <!-- 組合清單 -->
        <article class="mb-2 order-5" v-if="item.packs[0] != null">
          <ul>
            <li class="p-3 bg-gray-50 rounded-md mb-1" v-for="(pack, idx) in item.packs" :key="idx">
              <template v-if="pack">
                <section class="flex flex-col" :class="{ 'mb-2': item.status !== 11 }">
                  <article class="flex justify-between items-center mb-2">
                    <span class="text-brown mr-2">{{ pack.name }}</span>
                    <span v-if="pack.spec" class="opacity-60">{{ pack.spec }}</span>
                  </article>
                  <article class="flex justify-between items-center">
                      <span class="text-sm text-gray-400 mr-3"><span class="font-bold text-pink-salmon">{{ pack.size }}</span> /入</span>
                      <span class="font-bold text-pink-salmon break-normal">{{ pack.quantity }} <span class="text-sm text-gray-400">組</span></span>
                      <span class="font-bold text-pink-salmon text-right">{{ pack.price }}</span>
                  </article>
                </section>
                <section v-if="pack.comment" class="text-brown border-t break-words">
                  <div class="py-1">{{pack.comment}}</div>
                </section>
                <order-progress :status="pack.status" :delivery="pack.delivery"/>
              </template>
            </li>
          </ul>
        </article>

        <!-- 收件人資訊 -->
        <div class="flex mb-2 items-center order-6 justify-end">
          <receiver-info :receiver="item.receiver" />
        </div>

        <!-- 退貨表單 -->
        <a-form-model v-if="shouldShowBankInfo" ref="bankForm" :model="bankForm" :rules="rules" class="py-3 border-t md:border-none order-7">
          <div v-if="item.paymentTypeCode == 'ECPay.WebATM'" class="lg:mx-12 md:m-4">
            <p class="item-title"><span>銀行代號</span><span class="must">*</span></p>
            <a-form-model-item prop="bank">
              <a-input type="text" :maxLength="3" @change="handleBankChange" placeholder="請輸入銀行代號" v-model="bankForm.bank" />
            </a-form-model-item>
          </div>
          <div v-if="item.paymentTypeCode == 'ECPay.WebATM'" class="lg:mx-12 md:m-4">
            <p class="item-title"><span>銀行帳號</span><span class="must">*</span></p>
            <a-form-model-item prop="account">
              <a-input type="text" placeholder="請輸入你的銀行帳號" :maxLength="16" @change="handleBankAccountChange" v-model="bankForm.account" autocomplete="nope" />
            </a-form-model-item>
          </div>
          <div v-if="item.paymentTypeCode == 'ECPay.WebATM'" class="lg:mx-12 md:m-4">
            <p class="item-title"><span>戶名</span><span class="must">*</span></p>
            <a-form-model-item prop="name">
              <a-input type="text" placeholder="請輸入你的戶名" :maxLength="20" v-model="bankForm.name" autocomplete="nope" />
            </a-form-model-item>
          </div>
          <div class="lg:mx-12 md:m-4">
            <p class="item-title"><span>退貨理由</span><span class="must">*</span></p>
            <a-form-model-item prop="reason">
              <a-input type="text" placeholder="退貨理由" :maxLength="200" v-model="bankForm.reason" autocomplete="nope" />
            </a-form-model-item>
          </div>
          <div v-if="item.paymentTypeCode == 'ECPay.WebATM'" class="lg:mx-12 md:m-4">
            <p class="item-title"><span>存摺圖片</span><span class="must">*</span></p>
            <a-form-model-item prop="passbook">
              <Upload title="請上傳存摺圖片" name="passbook" :value="bankForm.passbook.value" @onChange="value => handleChange('passbook', value)" />
            </a-form-model-item>
          </div>
        </a-form-model>

        <!-- 操作按鈕 -->
        <article v-if="item.allow_cancel | item.allow_return" class="pb-3 md:border-none md:flex md:justify-end order-8">
          <!-- PC TODO 待退款/已退款-->
          <!--  <article class="hidden md:block w-full p-2">
            <div class="flex justify-between ">
              <div class="flex justify-between w-1/2 items-center"><span class="text-brown text-lg">待退款</span><span class="text-lg text-pink-salmon">$??</span></div>
            </div>
            <div class="flex justify-between items-center">
              <span class="mt-2 md:mt-0 opacity-50">已退款 ??${{ item.mockRefoundFinished }} 於 ??2021.04.06</span>
            </div>
          </article> -->
          <button v-if="item.status === 0 && item.payment.account === ''" class="btn-forms mt-2 mb-4 btn-block p-2 md:w-1/2 md:inline-block" @click="handlePayOrder(item.no)">結帳</button>
          <button v-if="item.allow_cancel" class="btn-forms mt-2 mb-4 btn-block p-2 md:w-1/2 md:inline-block" @click="handleCancelOrder(item.no)">取消</button>
          <template v-if="item.allow_return">
            <button v-if="!shouldShowBankInfo" class="btn-forms mt-2 mb-4 btn-block p-2 md:w-1/2 md:inline-block" @click="showBankInfo">退貨</button>
            <div v-else class="w-full md:w-1/2 flex">
              <button class="btn-forms btn-light mx-2 btn-block p-2 md:w-1/2 md:inline-block" @click="hideBankInfo">取消</button>
              <button class="btn-forms mx-2 btn-block p-2 md:w-1/2 md:inline-block " @click="handleSubmitBankForm">確認</button>
            </div>
          </template>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
// NOTE api status
// ANCHOR 顯示可以點擊的取消按紐
// 0: 未付款
// 1: 已付款
// ----------------------------------------------
// ANCHOR 顯示不可點擊的（disabled）取消按紐，按鈕文字改為【申請取消中】
// 10: 用戶請求取消
// ----------------------------------------------
// ANCHOR 顯示可以點擊的退貨按紐
// 2: 交易完成
// 3: 部分出貨(訂單狀態)
// ----------------------------------------------
// ANCHOR 以下狀態僅顯示不可點擊的（disabled）退貨按紐，按
// 14: 用戶請求退貨(退貨中)
// ----------------------------------------------
// ANCHOR 不顯示任何按紐
// 11: 交易關閉(同意取消，並且已退款)
// 12: 交易關閉(同意取消，並且已退款及退貨)
// 13: 部分退貨(訂單狀態)

// 0: 未付款
// 1: 已付款
// 2: 交易完成
// 3: 部分出貨(訂單狀態)
// 10: 用戶請求取消
// 11: 交易關閉(同意取消，並且已退款)
// 12: 交易關閉(同意取消，並且已退款及退貨)
// 13: 部分退貨(訂單狀態)
// 14: 用戶請求退貨(退貨中)

import { mockDataSpacks } from '@/data/mockData.js'
import Upload from '@/components/Upload.vue'
import PaymentInfo from './PaymentInfo.vue'
import ReceiverInfo from './ReceiverInfo.vue'
import OrderProgress from './OrderProgress.vue'
import { mapActions, mapGetters } from 'vuex'
import message from 'ant-design-vue/es/message'

export default {
  name: 'OrderItem',
  props: ['item'],
  components: {
    Upload,
    PaymentInfo,
    ReceiverInfo,
    OrderProgress,
  },
  data() {
    return {
      isDebug: true,
      currentPackIdx: '',
      currentSelectedSpackValue: '',
      currentSelectedSpackIndex: '',
      form: {},
      mockDataSpacks,
      show: {
        shippingStatus: false
      },
      shouldShowBankInfo: false,
      bankForm: {
        bank: '',
        account: '',
        name: '',
        reason: '',
        passbook: {}
      },
      rules: {
        bank: { required: false, message: '請輸入銀行代號', pattern: /\d{3}/, trigger: 'change' },
        account: { required: false, message: '請輸入銀行帳號', trigger: 'change' },
        name: { required: false, message: '請輸入你的戶名', trigger: 'change' },
        reason: { required: true, message: '請輸入退貨理由', trigger: 'change' },
        passbook: { required: true, validator: (rule, value, callback) => this.validatorPic(value, callback, '請上傳存摺圖片'), trigger: 'change' }
      },
      statusList: {
        '0':  '未付款',
        '1':  '已付款',
        '2':  '交易完成',
        '3':  '部分出貨',
        '10':  '用戶請求取消',
        '11':  '交易關閉',
        '12':  '交易關閉',
        '13':  '部分退貨',
        '14':  '退貨中',
      }
    }
  },
  computed: {
    ...mapGetters(['returnShowBankTrigger', 'user']),
    orderImage({ item }) {
      if (item && item.product) {
        return item.product.image
      }
      return require('@/assets/img/status/not_found_img.jpg')
    },
    currentStatus({ item }) {
      return this.statusList[item.status]
    },
    cocoUsed() {
      let list = this.item.origin_payment.filter(x => x.code == 'Cash.COCO')
      if(list.length > 0) { 
        return list.map(x => x.cash).reduce((a, b) => a + b)
      }
      return 0
    }
  },
  methods: {
    ...mapActions(['getOrders', 'setLoading', 'orderPayment', 'cancelOrders', 'returnOrders', 'cleanOrders']),
    handleBankChange(e) {
      this.bankForm.bank = e.target.value.replace(/\D/g,'')
    },
    handleBankAccountChange(e) {
      this.bankForm.account = e.target.value.replace(/\D/g,'')
    },
    handleCopied() {
      message.success('已複製')
    },
    updateCounter(v, idx) {
      this.calc[idx].count = v
    },
    handleSubmitBankForm() {
      this.$refs.bankForm.validate(valid => {
        if (!valid) return
        this.handleReturnGoods()
      })
    },
    async handleReturnGoods() {
      const orderItem = this.item
      console.log(orderItem)
      const {
        item: { no },
        bankForm
      } = this
      const formData = new FormData()
      formData.append('reason', bankForm.reason)
      formData.append('bank', bankForm.bank)
      formData.append('account', bankForm.account)
      formData.append('name', bankForm.name)
      formData.append('paymenttypecode', orderItem.paymentTypeCode)
      if(bankForm.passbook.file != undefined && bankForm.passbook.file != null) {
        formData.append('passbook', bankForm.passbook.file)
      } 
      else if(bankForm.passbook.value != undefined && bankForm.passbook.value != null && bankForm.passbook.value.length > 0)
      {
        formData.append('passbookval', bankForm.passbook.value)
      }

      await this.returnOrders({
        no,
        data: formData
      })
      await this.cleanOrders()
      await this.getOrders()
      this.shouldShowBankInfo = false
    },
    async handlePayOrder(id) {
      await this.orderPayment(id)
      await this.cleanOrders()
      await this.getOrders()
    },
    async handleCancelOrder(id) {
      // try {
      //   const response = await CancelOrders(id)
      //   if (response.err) {
      //     alert(response.msg)
      //   }
      // } catch (error) {
      //   console.log(error)
      // }

      await this.cancelOrders(id)
      await this.cleanOrders()
      await this.getOrders()
    },
    showBankInfo() {
      this.shouldShowBankInfo = true
      if(this.user.passbook != '') {
        this.bankForm.passbook.value = this.user.passbook
      }
    },
    hideBankInfo() {
      this.shouldShowBankInfo = false
    },
    handleChange(column, { file, value }) {
      this.bankForm[column] = {
        file,
        value
      }
      this.$refs.bankForm.validateField(column)
    },
    imgNotFound(e) {
      const { innerWidth } = window
      if (innerWidth <= 768) {
        e.target.src = require('@/assets/img/status/not_found_img_72x72.jpg')
      } else {
        e.target.src = require('@/assets/img/status/not_found_img.jpg')
      }
    },
    validatorPic(value, callback, errorText) {
      if (!value.value) {
        callback(errorText)
      }
      callback()
    },
    handleClickImage() {
      this.$router.push({ name: 'productDetail', params: { id: this.item.product.id } })
    }
  },
  watch: {
    'this.returnShowBankTrigger': {
      immediate: true,
      handler(payload) {
        this.$nextTick(() => {
          this.shouldShowBankInfo = payload
        })
      }
    }
  }
}
</script>

<style lang="postcss" scope>
/*配送進度*/
.btn-ico-triangle {
  @apply ml-4;
  @apply w-2;
  @apply h-2;
  width: 0.75rem;
  height: 0.75rem;
}
/*宅配動畫*/

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
